import { secure_instance } from "api/axios/axios-config";
var role_template_id;
export const GET_ALL_ROLE_TEMPLATES = "[Role Templates] GET_ALL_ROLE_TEMPLATES";
export const GET_ALL_CATEGORY_TYPES = "[Role Templates] GET_ALL_CATEGORY_TYPES";
export const ADD_ROLE_TEMPLATE = "[Role Templates] ADD_ROLE_TEMPLATE";
export const HANDLE_CHANGE = "[Role Templates] HANDLE_CHANGE";
export const SET_ROLE_TEMPLATE_ID = "[Role Templates] SET_ROLE_TEMPLATE_ID";
export const GET_SPECIFIC_ROLE_TEMPLATE =
	"[Role Templates] GET_SPECIFIC_ROLE_TEMPLATE";
export const UPDATE_ROLE_TEMPLATE = "[Role Templates] UPDATE_ROLE_TEMPLATE";
export const GET_ALL_PERSONALITY = "[Role Templates] GET_ALL_PERSONALITY";
export const HANDLE_PERSONALITY_CHANGE =
	"[Role Templates] HANDLE_PERSONALITY_CHANGE";
export const HANDLE_CATEGORY_CHANGE =
	"[Role Templates] HANDLE_CATEGORY_CHANGE";
export const ADD_PERSONALITY = "[Role Templates] ADD_PERSONALITY";
export const ADD_CATEGORY = "[Role Templates] ADD_CATEGORY";

export const DELETE_ROLE_TEMPLATE = "[Role Templates] DELETE_ROLE_TEMPLATE";
export const DELETE_PERSONALITY = "[Role Templates] DELETE_PERSONALITY";

export const getAllRoleTemplates = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: "/v1/role_templates/",
				method: "Get",
			});
			dispatch({
				type: GET_ALL_ROLE_TEMPLATES,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};

export const getSpecificRoleTemplates = (id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/role_templates/${id}`,
				method: "Get",
			});
			// console.log(request, "specificRole");
			dispatch({
				type: GET_SPECIFIC_ROLE_TEMPLATE,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};

export const updateRoleTemplate = (data, id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/role_templates/${id}`,
				method: "Patch",
				data,
			});
			if (request.status === 200) {
				alert("Role Template Updated");
				// console.log(request);
			}
			dispatch({
				type: UPDATE_ROLE_TEMPLATE,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};

export const deleteRoleTemplate = (id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/role_templates/${id}`,
				method: "Delete",
			});
			if (request.status === 200) {
				alert("Role Template Deleted");
				window.location.reload();
				// console.log(request);
			}
			dispatch({
				type: DELETE_ROLE_TEMPLATE,
			});
		} catch (error) {
			//
		}
	};
};

export const deletePersonality = (id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/personality_mappings/${id}`,
				method: "Delete",
			});
			if (request.status === 200) {
				alert("Personality Deleted");

				// console.log(request);
			}
			dispatch({
				type: DELETE_PERSONALITY,
			});
		} catch (error) {
			//
		}
	};
};

export const setRoleTemplateId = (id) => {
	return {
		type: SET_ROLE_TEMPLATE_ID,
		payload: id,
	};
};

export const addRoleTemplate = (data) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: "/v1/role_templates/",
				method: "Post",
				data: { 
					description: data.description,
					name: data.name
				},
			});
			
			if (request.status === 201) {
				alert("Role Template Added");
			}
			console.log("coming", {
				role_template_id:role_template_id,
				category_type_id: data
			});
			// console.log(request.data._id, "added");
			role_template_id = request.data._id
			if(request.data._id){
				
				const requestCategory = await secure_instance.request({
					url: "/v1/category_mappings/",
					method: "Post",
					data:{
						role_template_id: request.data._id,
						category_type_id: data.category
					},
				});
			}
			dispatch({
				type: ADD_ROLE_TEMPLATE,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};

export const getAllPersonality = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: "/v1/personality_types/",
				method: "Get",
			});

			console.log(request, " personalsa");
			dispatch({
				type: GET_ALL_PERSONALITY,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};

export const addPersonality = (data) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: "/v1/personality_mappings/",
				method: "Post",
				data,
			});

			console.log(request, " personaladd");
			dispatch({
				type: ADD_PERSONALITY,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};

export const handleChange = (data) => {
	return (dispatch) => {
		dispatch({
			type: HANDLE_CHANGE,
			payload: data,
		});
	};
};

export const handlePersonalityChange = (data) => {
	return {
		type: HANDLE_PERSONALITY_CHANGE,
		payload: data,
	};
};

export const handleCategoryChange = (data) => {
	return {
		type: HANDLE_CATEGORY_CHANGE,
		payload: data,
	};
};

export const getAllCategories = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: "/v1/category_types/",
				method: "Get",
			});
			dispatch({
				type: GET_ALL_CATEGORY_TYPES,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};
