/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import classNames from "classnames";
import { Card, CardBody, CardHeader, Row, Col, Button } from "reactstrap";

import ReactTable from "components/ReactTable/ReactTable.js";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../redux/actions/roleTemplates";

const ReactTables = () => {
    const dispatch = useDispatch();
    const roleTemplates = useSelector(
        (state) => state.roleTemplates.roleTemplates
    );

    // console.log(roleTemplates, "role");

    useEffect(() => {
        dispatch(Actions.getAllRoleTemplates());
    }, [dispatch]);

    const history = useHistory();

    const addRoleTemplates = () => {
        history.push("/admin/add-role-templates");
    };

    return (
        <>
            <div className="content">
                <Row className="mt-5">
                    <Col xs={12} md={12}>
                        <div className="d-flex mt-2 mb-3">
                            <Button
                                className="btn-fill"
                                color="default"
                                type="submit"
                                onClick={addRoleTemplates}
                            >
                                Add Role Templates
                            </Button>
                        </div>
                        <Card>
                            <CardHeader>Role Templates</CardHeader>
                            <CardBody>
                                <ReactTable
                                    data={
                                        roleTemplates.length > 0
                                            ? roleTemplates.map((prop, key) => {
                                                  return {
                                                      id: prop.id,
                                                      name: prop.name,
                                                      description:
                                                          prop.description,
                                                      actions: (
                                                          // we've added some custom button actions
                                                          <div className="actions-right">
                                                              {/* use this button to add a edit kind of action */}
                                                              <Button
                                                                  onClick={() => {
                                                                      // let obj = data.find((o) => o.id === key);
                                                                      dispatch(
                                                                          Actions.setRoleTemplateId(
                                                                              prop.id
                                                                          )
                                                                      );
                                                                      history.push(
                                                                          `/admin/add-role-templates/${prop.id}`
                                                                      );
                                                                      // alert(
                                                                      // 	"You've clicked EDIT button on \n{ \nName: " +
                                                                      // 		obj.name +
                                                                      // 		", \nposition: " +
                                                                      // 		obj.position +
                                                                      // 		", \noffice: " +
                                                                      // 		obj.office +
                                                                      // 		", \nage: " +
                                                                      // 		obj.age +
                                                                      // 		"\n}.",
                                                                      // );
                                                                  }}
                                                                  color="warning"
                                                                  size="sm"
                                                                  className={classNames(
                                                                      "btn-icon btn-link like btn-neutral"
                                                                  )}
                                                              >
                                                                  <i className="tim-icons icon-pencil" />
                                                              </Button>{" "}
                                                              {/* use this button to remove the data row */}
                                                              <Button
                                                                  onClick={() => {
                                                                      dispatch(
                                                                          Actions.deleteRoleTemplate(
                                                                              prop.id
                                                                          )
                                                                      );
                                                                      // alert(prop.id);
                                                                      // var newdata = data;
                                                                      // newdata.find((o, i) => {
                                                                      // 	if (o.id === key) {
                                                                      // 		// here you should add some custom code so you can delete the data
                                                                      // 		// from this component and from your server as well
                                                                      // 		data.splice(i, 1);
                                                                      // 		console.log(data);
                                                                      // 		return true;
                                                                      // 	}
                                                                      // 	return false;
                                                                      // });
                                                                      // setData(newdata);
                                                                  }}
                                                                  color="danger"
                                                                  size="sm"
                                                                  className={classNames(
                                                                      "btn-icon btn-link like"
                                                                  )}
                                                              >
                                                                  <i className="tim-icons icon-simple-remove" />
                                                              </Button>{" "}
                                                          </div>
                                                      ),
                                                  };
                                              })
                                            : []
                                    }
                                    filterable
                                    resizable={false}
                                    columns={[
                                        {
                                            Header: "Name",
                                            accessor: "name",
                                        },
                                        {
                                            Header: "Description",
                                            accessor: "description",
                                        },
                                        {
                                            Header: "Actions",
                                            accessor: "actions",
                                            sortable: false,
                                            filterable: false,
                                        },
                                    ]}
                                    defaultPageSize={10}
                                    showPaginationTop
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default ReactTables;
