import { secure_instance } from "api/axios/axios-config";

export const HANDLE_SELECT_CHANGE = "[Qualifications] HANDLE_SELECT_CHANGE";
export const HANDLE_CHANGE = "[Qualifications] HANDLE_CHANGE";
export const SUBMIT_QUALIFICATIONS = "[Qualifications] SUBMIT_QUALIFICATIONS";

export const DELETE_QUALIFICATIONS = "[Qualifications] DELETE_QUALIFICATIONS";

export const submitQualifications = (data, roleTemplateId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: "/v1/qualification_mappings/",
				method: "Post",
				data: {
					role_template_id: roleTemplateId,
					difficulty: data.difficulty.label,
					test_pack_id: data.qualifications.value,
				},
			});
			if (request.status === 201) {
				alert("Qualification Added");
				console.log(request);
			}

			dispatch({
				type: SUBMIT_QUALIFICATIONS,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};

export const deleteQualifications = (id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/qualification_mappings/${id}`,
				method: "Delete",
			});
			if (request.status === 200) {
				alert("Qualification Deleted");
				// console.log(request);
			}

			dispatch({
				type: DELETE_QUALIFICATIONS,
			});
		} catch (error) {
			//
		}
	};
};

export const handleSelectChange = (data) => {
	return {
		type: HANDLE_SELECT_CHANGE,
		payload: data,
	};
};

export const handleChange = (data) => {
	return {
		type: HANDLE_CHANGE,
		payload: data,
	};
};
