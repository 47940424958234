// import { instance } from "./axios-config";
import store from "../../index";
import * as authActions from "../../redux/actions/authAction";

export const addInterceptors = (instance) => {
	instance.interceptors.request.use(addAccessToken, handleRequestError);
	instance.interceptors.response.use(handleResponseOK, handleResponseError);
};

export const addAccessToken = (config) => {
	const state = store.getState();
	const user = state.user.user;

	const accessToken = user.accessToken;

	if (!accessToken) {
		store.dispatch(authActions.refreshToken());
	} else {
		return {
			...config,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		};
	}
};

export const handleRequestError = (error) => {
	// console.log("handleRequestError", error);
	return Promise.reject(error);
};

export const handleResponseOK = (response) => {
	// console.log("handleResponseOK", response);
	return response;
};

export const handleResponseError = (error) => {
	// console.log("handleResponseError", error);
	if (error.response?.status === 401) {
		// return handleRefreshToken(error.config);
	}
	return Promise.reject(error);
};

// const handleRefreshToken = (config) => {
// 	return new Promise((resolve, reject) => {
// 		const refreshToken = localStorage.getItem("rnr_refresh_token");
// 		console.log("Asking for new access token by using : ", refreshToken);
// 		instance
// 			.request({
// 				url: `/token`,
// 				headers: { "x-refresh-token": refreshToken },
// 				method: "get",
// 			})
// 			.then((response) => {
// 				const accessToken = response.headers["x-token"];
// 				console.log("RESPONSE: ");
// 				console.log(response.headers);
// 				localStorage.setItem("rnr_access_token", accessToken);
// 				console.log("new Access Token", accessToken);
// 				instance
// 					.request({
// 						...config,
// 						data: config.data ? JSON.parse(config.data) : null,
// 						headers: { "x-token": accessToken },
// 					})
// 					.then((res) => {
// 						resolve(res);
// 					})
// 					.catch((err) => {
// 						reject(err);
// 					});
// 			})
// 			.catch((err) => {
// 				reject(err);
// 			});
// 	});
// };

// axios.interceptors.request.use((config) => {
//     const accessToken = localStorage.getItem('accessToken');
//     return { ...config, headers: { 'accesstoken': accessToken } };
// }, (error) => {
//     return Promise.reject(error);
// });

// // Add a response interceptor
// axios.interceptors.response.use(function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   }, function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     return Promise.reject(error);
//   });
