import { instance, secure_instance } from "../../api/axios/axios-config";
export const SIGN_IN = "[Auth] SIGN_IN";
export const SIGN_IN_FAILED = "[Auth] SIGN_IN_FAILED";
export const REFRESH_TOKEN = "[Auth] REFRESH_TOKEN";
export const SIGN_OUT = "[Auth] SIGN_OUT";

export const signIn = (email, password) => {
	return async (dispatch) => {
		try {
			const request = await instance.request({
				url: `v1/auth/login/admin`,
				method: "post",
				data: {
					email,
					password,
				},
			});

			dispatch({
				type: SIGN_IN,
				payload: request.data,
			});
		} catch (err) {
			dispatch({
				type: SIGN_IN_FAILED,
				payload: "Invalid Username or Password",
			});
		}
	};
};

export const signOut = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/auth/logout`,
				method: "post",
			});

			dispatch({
				type: SIGN_OUT,
			});

			if (request.data.message === "logged out") {
				window.location.href = "/auth/login";
			}
		} catch (e) {
			//
		}
	};
};

export const setErrorMessage = (message) => {
	return {
		type: SIGN_IN_FAILED,
		payload: message,
	};
};

export const refreshToken = () => {
	return async (dispatch) => {
		try {
			const request = await instance.request({
				url: "v1/auth/refresh",
				method: "post",
			});

			dispatch({
				type: REFRESH_TOKEN,
				payload: request.data,
			});
		} catch (e) {
			window.location.href = "/auth/login";
		}
	};
};
