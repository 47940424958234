import baseURL from "./BaseUrl";

/**
 *
 * @param {string} email Email to be validated
 * @returns {boolean} true if email is valid else false
 */
export const isEmailValid = function (email) {
	const validationRegex = new RegExp(
		/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g,
	);
	return validationRegex.test(email);
};

/**
 *
 * @param {string} password Password to be validated
 */
export const isPasswordValid = function (password) {
	const validationRegex = new RegExp(/[A-Za-z0-9._%+-@#$!^&*()={},~`]{6,60}/g);
	return validationRegex.test(password);
};

/**
 * @param {string} text Check if only contain text
 * @returns {boolean} true if text is valid or false
 */
export const isTextValid = function (text) {
	const validationRegex = new RegExp(/[a-zA-Z]{3,}/);
	return text ? validationRegex.test(text) : false;
};

/**
 * @param {Number} number Check if only contain text
 * @returns {boolean} true if text is valid or false
 */
export const isNumberValid = function (number) {
	const validationRegex = new RegExp(/[0-9]/);
	return validationRegex.test(number);
};

/**
 * @param {string} webUrl Check if only contain text
 * @returns {boolean} true if text is valid or false
 */
export const isUrlValid = function (webUrl) {
	const validationRegex = new RegExp(
		/^((https):\/\/)(www.)[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
	);
	return validationRegex.test(webUrl);
};

/**
 * @param {string} zipcode Check if only contain text
 * @returns {boolean} true if text is valid or false
 */
export const isZipcodeValid = function (zipcode) {
	const validationRegex = new RegExp(/[0-9]{5,5}/);
	return validationRegex.test(zipcode);
};

/**
 *
 * @summary This function will perform fetch api request and return result or error
 * @param {string} path request url path
 * @param {string} method Request method
 * @param {object} auth AuthContext for the app
 * @param {object} bodyData Request body data
 * @returns
 */
export const openURL = async function (path, method, authContext, bodyData) {
	const { expiration_timestamp } = authContext;

	const timestamp = Math.floor(Date.now() / 1000);

	if (timestamp > expiration_timestamp) {
		await authContext.authRefresh();
	}

	try {
		const response = await fetch(`${baseURL}${path}`, {
			method: method,
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${authContext.access_token}`,
			},

			body: JSON.stringify(bodyData),
		});

		const json = await response.json();

		if (!response.ok) {
			return { error: json };
		}

		return { result: json };
	} catch (err) {
		return { error: "Something went wrong" };
	}
};
