import React, { useEffect, useState } from "react";
import {
	Row,
	Col,
	Label,
	Input,
	FormGroup,
	Button,
	Card,
	CardHeader,
	CardBody,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "reactstrap";
import Select from "react-select";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import ReactTable from "../components/ReactTable/ReactTable";
import { useDispatch, useSelector } from "react-redux";
import * as TestPackActions from "../redux/actions/testPack";
import * as QuestionActions from "../redux/actions/questions";

export default function TestPack() {
	// const [isPublished, setIsPublished] = useState(false);
	const [modal, setModal] = useState(false);
	const dispatch = useDispatch();
	// const match = useRouteMatch();
	const history = useHistory();

	const addQuestions = () => {
		history.push("/admin/questions");
	};

	const submitForm = () => {
		dispatch(TestPackActions.submitTestPack({ ...form }));
		// console.log(form, "test");
	};

	const handleChange = (target) => {
		dispatch(TestPackActions.handleChange(target));
	};

	const setTestPackPublish = () => {
		dispatch(TestPackActions.publishTestPack(id));
	};
	// const isQuestionDeleted = useSelector(
	// 	(state) => state.testPackReducer.isQuestionDeleted,
	// );

	const id = useSelector((state) => state.testPackReducer.newTestPackId);

	// console.log(id, "idada");

	const isEditable = useSelector((state) => state.testPackReducer.isEditable);

	const form = useSelector((state) => state.testPackReducer.testPackForm);

	const TestPackId = useSelector(
		(state) => state.testPackReducer.newTestPackId,
	);

	useEffect(() => {
		if (isEditable) {
			dispatch(TestPackActions.getSpecificTestPack(id));
		}
	}, [dispatch, id, isEditable]);

	const updateForm = () => {
		dispatch(TestPackActions.updateTestPack({ ...form }, id));
	};

	const handleSelectChange = (value) => {
		dispatch(TestPackActions.handleSelectChange(value));
	};

	const handleSelectCategory = (value) => {
		dispatch(TestPackActions.handleSelectCategory(value));
	};

	// const isSubmitted = useSelector((state) => state.testPackReducer.isSubmitted);

	const toggle = () => setModal(!modal);
	// console.log(form.questions);

	return (
		<div className='content'>
			<div className='d-flex mb-3'>
				<Button
					className='btn-fill'
					color='default'
					type='submit'
					onClick={setTestPackPublish}>
					Publish
				</Button>
			</div>
			<Row>
				<Col lg='5' md='6' sm='3'>
					<Label sm='5'>Author Name</Label>
					<FormGroup>
						<Input
							name='authorName'
							type='text'
							value={form.authorName}
							onChange={(e) => handleChange(e.target)}
						/>
					</FormGroup>
				</Col>
				<Col lg='5' md='6' sm='3'>
					<Label sm='8'>TestPack Type</Label>
					<Select
						className='react-select info'
						classNamePrefix='react-select'
						name='singleSelect'
						value={form.type}
						onChange={(value) => handleSelectChange(value)}
						options={[
							{ value: "qualification", label: "Qualification" },
							{ value: "personality", label: "Personality" },
						]}
					/>
				</Col>
				<Col sm='10'></Col>
			</Row>

			<Row style={{ paddingTop: "0px" }}>
				{form.type && form.type.label === "Qualification" && (
					<Col lg='5' md='6' sm='3'>
						<Label sm='4'>Qualification</Label>
						<FormGroup>
							<Input
								name='qualification'
								type='text'
								value={form.qualification}
								onChange={(e) => handleChange(e.target)}
							/>
						</FormGroup>
					</Col>
				)}
				<Col lg='5' md='6' sm='3'>
					{form.type && form.type.label === "Personality" && (
						<>
							<Label sm='4'>Category</Label>
							<Select
								className='react-select info'
								classNamePrefix='react-select'
								name='singleSelect'
								value={form.category}
								onChange={(value) => handleSelectCategory(value)}
								options={[
									{ value: "1", label: "foobar" },
									{ value: "2", label: "Is great" },
								]}
								placeholder='Category'
							/>
						</>
					)}
				</Col>
			</Row>
			{isEditable ? (
				<div>
					<div className='d-flex align-items-center justify-content-center mt-5 mb-3'>
						<Button
							className='btn-fill'
							color='default'
							type='submit'
							onClick={updateForm}>
							Update
						</Button>
					</div>
					<div className='content'>
						<Row className='mt-2'>
							<Col xs={12} md={12}>
								<div className='d-flex mt-5 mb-3'>
									<Button
										className='btn-fill'
										color='default'
										type='submit'
										onClick={addQuestions}>
										Add Questions
									</Button>
								</div>
								<Card>
									<CardHeader>Questions</CardHeader>
									<CardBody>
										{form?.questions?.length > 0 && (
											<ReactTable
												data={form.questions.map((prop, key) => {
													return {
														id: prop._id,
														problem_statement_type: prop.problem_statement.type,
														question_type: prop.type,
														answer: prop.answer,
														difficulty: prop.difficulty,
														duration: `${prop.duration.minutes}:${prop.duration.seconds}`,
														actions: (
															<div className='actions-right'>
																<Button
																	onClick={() => {
																		dispatch(
																			QuestionActions.setQuestionId(prop._id),
																		);
																		// console.log(prop, "p");
																		// dispatch(
																		// 	QuestionActions.editQuestion(prop),
																		// );
																		history.push(
																			`/admin/questions/${prop._id}`,
																		);
																	}}
																	color='warning'
																	size='sm'
																	className={classNames(
																		"btn-icon btn-link like btn-neutral",
																	)}>
																	<i className='tim-icons icon-pencil' />
																</Button>{" "}
																<Modal isOpen={modal} toggle={toggle}>
																	<ModalHeader toggle={toggle}>
																		Delete Confirmation
																	</ModalHeader>
																	<ModalBody>
																		Do you really want to delete this question?
																	</ModalBody>
																	<ModalFooter>
																		<Button
																			color='primary'
																			onClick={() => {
																				dispatch(
																					TestPackActions.deleteQuestion(
																						TestPackId,
																						prop._id,
																					),
																				);
																				setModal(!modal);
																			}}>
																			Yes, Delete
																		</Button>{" "}
																		<Button color='secondary' onClick={toggle}>
																			Cancel
																		</Button>
																	</ModalFooter>
																</Modal>
																<Button
																	onClick={toggle}
																	color='danger'
																	size='sm'
																	className={classNames(
																		"btn-icon btn-link like",
																	)}>
																	<i className='tim-icons icon-simple-remove' />
																</Button>{" "}
															</div>
														),
													};
												})}
												filterable={false}
												resizable={false}
												columns={[
													{
														Header: "Problem Statement Type",
														accessor: "problem_statement_type",
														sortable: false,
														filterable: false,
													},
													{
														Header: "Question Type",
														accessor: "question_type",
														sortable: false,
														filterable: false,
													},
													{
														Header: "Answer",
														accessor: "answer",
														sortable: false,
														filterable: false,
													},
													{
														Header: "Difficulty",
														accessor: "difficulty",
														sortable: false,
														filterable: false,
													},
													{
														Header: "Duration",
														accessor: "duration",
														sortable: false,
														filterable: false,
													},
													{
														Header: "Actions",
														accessor: "actions",
														sortable: false,
														filterable: false,
													},
												]}
												defaultPageSize={10}
												className='-striped -highlight'
											/>
										)}
									</CardBody>
								</Card>
							</Col>
						</Row>
					</div>
				</div>
			) : (
				<div className='d-flex align-items-center justify-content-center mt-5 mb-3'>
					<Button
						className='btn-fill'
						color='default'
						type='submit'
						onClick={submitForm}>
						Save
					</Button>
				</div>
			)}
		</div>
	);
}
