/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth/Auth.js";
import AdminLayout from "layouts/Admin/Admin.js";
import RTLLayout from "layouts/RTL/RTL.js";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import "assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.2.0";
import "assets/demo/demo.css";
import allReducers from "./redux/reducers/index";
import thunk from "redux-thunk";


const store = createStore(allReducers, applyMiddleware(thunk));

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<Switch>
				<Route path='/auth' render={(props) => <AuthLayout {...props} />} />
				<Route path='/admin' render={(props) => <AdminLayout {...props} />} />
				<Route path='/rtl' render={(props) => <RTLLayout {...props} />} />
				<Redirect from='/' to='/auth/login' />
			</Switch>
		</BrowserRouter>
	</Provider>,
	document.getElementById("root"),
);

export default store;
