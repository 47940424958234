import * as Actions from "../actions/authAction";

const initialState = {
	user: {
		accessToken: null,
		userId: null,
	},
	isLoggedIn: false,
	errorMessage: "",
};

const authReducer = (state = initialState, action) => {
	const data = action.payload;

	switch (action.type) {
		case Actions.SIGN_IN:
			return (state = {
				...state,
				isLoggedIn: true,
				errorMessage: "",
				user: {
					...state.user,
					accessToken: data.access_token,
					userId: data.user_id,
				},
			});

		case Actions.SIGN_IN_FAILED:
			return (state = {
				...state,
				errorMessage: data,
			});
		case Actions.REFRESH_TOKEN:
			return (state = {
				...state,
				user: {
					...state.user,
					accessToken: data.access_token,
					userId: data.user_id,
				},
			});

		case Actions.SIGN_OUT:
			return (state = {
				state: initialState,
			});
		default:
			return state;
	}
};

export default authReducer;
