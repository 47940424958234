import React, { useEffect, useState } from "react";
import classNames from "classnames";
import ReactTable from "../components/ReactTable/ReactTable.js";
import { useHistory } from "react-router-dom";
import {
	Row,
	Col,
	Label,
	Input,
	FormGroup,
	Button,
	Card,
	CardHeader,
	CardBody,
} from "reactstrap";
import Select from "react-select";
import { ReactComponent as Cross } from "../assets/img/cross.svg";
import { useDispatch, useSelector } from "react-redux";
import * as QualificationActions from "../redux/actions/qualifications";
import * as Actions from "../redux/actions/roleTemplates";
import * as TestPackActions from "../redux/actions/testPack";
// import { Redirect } from "react-router-dom";

export default function RoleTemplates() {
	const dispatch = useDispatch();

	const [openModal, setOpenModal] = useState(false);
	// const form = useSelector((state) => state.qualifications.qualificationsForm);

	const roleTemplateForm = useSelector((state) => state.roleTemplates.form);

	// const testPacks = useSelector((state) => state.testPackReducer.testPacks);

	const isEditable = useSelector((state) => state.roleTemplates.isEditable);
	// const isEditted = useSelector((state) => state.roleTemplates.isEditted);
	const isSubmitted = useSelector((state) => state.roleTemplates.isSubmitted);

	const personalities = useSelector(
		(state) => state.roleTemplates.personalities,
	);
	const categories = useSelector(
		(state) => state.roleTemplates.categories,
	);
	const personality = useSelector((state) => state.roleTemplates.personality);
	const category = useSelector((state) => state.roleTemplates.category);
	// console.log(personalities, "personas");


	useEffect(() => {
		dispatch(Actions.getAllPersonality());
	}, [dispatch, roleTemplateForm]);
	
	useEffect(() => {
		dispatch(Actions.getAllCategories());
	}, [dispatch, roleTemplateForm]);
	// console.log(testPacks, "sasa");

	useEffect(() => {
		dispatch(TestPackActions.getTestPack());
	}, [dispatch]);

	const id = useSelector((state) => state.roleTemplates.roleTemplateId);

	useEffect(() => {
		if (isEditable) {
			dispatch(Actions.getSpecificRoleTemplates(id));
		}
	}, [dispatch, id, isEditable]);

	// const submitData = () => {
	// 	dispatch(QualificationActions.submitQualifications({ ...form }, id));
	// 	if (isSubmitted) {
	// 		setOpenModal(false);
	// 	}
	// };

	const handleTextChange = (target) => {
		dispatch(Actions.handleChange(target));
	};

	const addRoleTemplate = () => {
		const data = {
			name: roleTemplateForm.name,
			description: roleTemplateForm.description,
			category: category.value
		};
		dispatch(Actions.addRoleTemplate(data));
	};

	const addPersonality = () => {
		const data = {
			role_template_id: id,
			personality_type_id: personality.value,
		};
		dispatch(Actions.addPersonality(data));
	};
	// const handleSelectChange = (value) => {
	// 	dispatch(QualificationActions.handleSelectChange(value));
	// };

	// const handleChange = (value) => {
	// 	dispatch(QualificationActions.handleChange(value));
	// };

	const updateRoleTemplate = () => {
		const data = {
			name: roleTemplateForm.name,
			description: roleTemplateForm.description,
		};
		dispatch(Actions.updateRoleTemplate(data, id));
	};
	const handleSelectChange = (value) => {
		dispatch(Actions.handleChange(value));
	};

	// console.log(roleTemplateForm, "form");

	// const [openModal, setOpenModal] = useState(false);

	// if (isSubmitted) {
	// 	setOpenModal(false);
	// }

	const handlePersonalityChange = (target) => {
		dispatch(Actions.handlePersonalityChange(target));
	};
	const handleCategoryChange = (target) => {
		dispatch(Actions.handleCategoryChange(target));
	};

	const history = useHistory();

	return (
		<div className='content'>
			<Row>
				<Col sm='10'>
					<Label sm='5'>Role Template Name</Label>
					<FormGroup>
						<Input
							name='name'
							type='text'
							value={roleTemplateForm.name}
							onChange={(e) => handleTextChange(e.target)}
						/>
					</FormGroup>
				</Col>
				<Col sm='10'>
					<Label sm='5'>Category</Label>
					<Select
						className='react-select info'
						classNamePrefix='react-select'
						name='singleSelect'
						value={category && category}
						onChange={(value) => handleCategoryChange(value)}
						options={categories.map((cat) => {
							return {
								value: cat.id,
								label: cat.name,
							};
						})}
					/>
				</Col>
				<Col sm='10'>
					<Label sm='4'>Description</Label>
					<FormGroup>
						<Input
							name='description'
							type='text'
							value={roleTemplateForm.description}
							onChange={(e) => handleTextChange(e.target)}
						/>
					</FormGroup>
				</Col>
			</Row>
			{isEditable ? (
				<div>
					<div className='d-flex align-items-center justify-content-center mt-5 mb-3'>
						<Button
							className='btn-fill'
							color='default'
							type='submit'
							onClick={updateRoleTemplate}>
							Update
						</Button>
					</div>
					<div className='content'>
						<Row className='mt-2'>
							<Col xs={12} md={12}>
								<div className='d-flex mt-5 mb-3'>
									<Button
										className='btn-fill'
										color='default'
										type='submit'
										onClick={() => history.push("/admin/add-qualifications")}>
										Add Qualifications
									</Button>
								</div>
								<Card>
									<CardHeader>Qualifications</CardHeader>
									<CardBody>
										{roleTemplateForm?.qualifications?.length > 0 && (
											<ReactTable
												data={roleTemplateForm.qualifications.map(
													(prop, key) => {
														return {
															id: prop.qual_mapping_id,
															qualificationName: prop.test_pack_name,
															difficulty: prop.test_pack_difficulty,
															actions: (
																<div className='actions-right'>
																	{/* use this button to add a edit kind of action */}
																	{/* <Button
																		onClick={() => {
																			history.push(
																				`/admin/role-templates/${prop.qual_mapping_id}`,
																			);
																		}}
																		color='warning'
																		size='sm'
																		className={classNames(
																			"btn-icon btn-link like btn-neutral",
																		)}>
																		<i className='tim-icons icon-pencil' />
																	</Button>{" "} */}
																	{/* use this button to remove the data row */}
																	<Button
																		onClick={() => {
																			dispatch(
																				QualificationActions.deleteQualifications(
																					prop.qual_mapping_id,
																				),
																			);
																		}}
																		color='danger'
																		size='sm'
																		className={classNames(
																			"btn-icon btn-link like",
																		)}>
																		<i className='tim-icons icon-simple-remove' />
																	</Button>{" "}
																</div>
															),
														};
													},
												)}
												filterable
												resizable={false}
												columns={[
													{
														Header: "Qualification Name",
														accessor: "qualificationName",
														sortable: false,
														filterable: false,
													},
													{
														Header: "Difficulty",
														accessor: "difficulty",
														sortable: false,
														filterable: false,
													},

													{
														Header: "Actions",
														accessor: "actions",
														sortable: false,
														filterable: false,
													},
												]}
												defaultPageSize={10}
												showPaginationTop
												showPaginationBottom={false}
												className='-striped -highlight'
											/>
										)}
									</CardBody>
								</Card>
							</Col>
							<Col xs={12} md={12}>
								<div className='d-flex mt-5 mb-3'>
									<Button
										className='btn-fill'
										color='default'
										type='submit'
										onClick={() => setOpenModal(true)}>
										Add Personality
									</Button>
								</div>
								{openModal && (
									<div
										style={{
											width: "50%",
											height: "350px",
											backgroundColor: "#D3D3D3",
											borderRadius: "12px",
											padding: "30px 0px 50px 100px",
											marginBottom: "30px",
											zIndex: "100",
										}}>
										<div
											className='d-flex justify-content-end w-100'
											style={{ marginTop: "-10px", paddingRight: "20px" }}>
											<Cross
												onClick={() => setOpenModal(false)}
												style={{ cursor: "pointer" }}
											/>
										</div>
										<Row>
											<Col lg='8' md='6' sm='6'>
												<Label sm='6'>Personality</Label>
												<Select
													className='react-select info'
													classNamePrefix='react-select'
													name='singleSelect'
													value={personality && personality}
													onChange={(value) => handlePersonalityChange(value)}
													options={personalities.map((per) => {
														return {
															value: per.id,
															label: per.name,
														};
													})}
												/>
											</Col>
										</Row>
										<div className='d-flex mt-3 mb-3'>
											<Button
												className='btn-fill'
												color='default'
												type='submit'
												onClick={addPersonality}>
												Save
											</Button>
										</div>
									</div>
								)}
								<Card>
									<CardHeader>Personality</CardHeader>
									<CardBody>
										{roleTemplateForm?.personalities?.length > 0 && (
											<ReactTable
												data={roleTemplateForm.personalities.map(
													(prop, key) => {
														return {
															id: prop.pers_mapping_id,
															personalityName: prop.personality_type_name,
															personalityId: prop.personality_type_id,
															actions: (
																<div className='actions-right'>
																	{/* use this button to add a edit kind of action */}
																	{/* <Button
																		onClick={() => {
																			// history.push(
																			// 	`/admin/role-templates/${prop.id}`,
																			// );
																		}}
																		color='warning'
																		size='sm'
																		className={classNames(
																			"btn-icon btn-link like btn-neutral",
																		)}>
																		<i className='tim-icons icon-pencil' />
																	</Button>{" "} */}
																	{/* use this button to remove the data row */}
																	<Button
																		onClick={() => {
																			dispatch(
																				Actions.deletePersonality(
																					prop.pers_mapping_id,
																				),
																			);
																		}}
																		color='danger'
																		size='sm'
																		className={classNames(
																			"btn-icon btn-link like",
																		)}>
																		<i className='tim-icons icon-simple-remove' />
																	</Button>{" "}
																</div>
															),
														};
													},
												)}
												filterable
												resizable={false}
												columns={[
													{
														Header: "Personality Id",
														accessor: "personalityId",
														sortable: false,
														filterable: false,
													},
													{
														Header: "Personality Name",
														accessor: "personalityName",
														sortable: false,
														filterable: false,
													},

													{
														Header: "Actions",
														accessor: "actions",
														sortable: false,
														filterable: false,
													},
												]}
												defaultPageSize={10}
												showPaginationTop
												showPaginationBottom={false}
												className='-striped -highlight'
											/>
										)}
									</CardBody>
								</Card>
							</Col>
						</Row>
					</div>
				</div>
			) : (
				<Col sm='10'>
					<div className='d-flex justify-content-center mt-3 mb-3'>
						<Button
							className='btn-fill'
							color='default'
							type='submit'
							onClick={addRoleTemplate}>
							Save
						</Button>
					</div>
				</Col>
			)}
			{isSubmitted && (
				<div className='content'>
					<Row className='mt-2'>
						<Col xs={12} md={12}>
							<div className='d-flex mt-5 mb-3'>
								<Button
									className='btn-fill'
									color='default'
									type='submit'
									onClick={() => history.push("/admin/add-qualifications")}>
									Add Qualifications
								</Button>
							</div>
							<Card>
								<CardHeader>Qualifications</CardHeader>
								<CardBody>
									{/* {roleTemplateForm?.qualifications?.length > 0 && ( */}
									<ReactTable
										data={roleTemplateForm.qualifications.map((prop, key) => {
											return {
												id: key,
												qualificationName: prop.test_pack_name,
												difficulty: prop.test_pack_difficulty,
												actions: (
													<div className='actions-right'>
														{/* use this button to add a edit kind of action */}
														<Button
															onClick={() => {
																history.push(
																	`/admin/role-templates/${prop.id}`,
																);
															}}
															color='warning'
															size='sm'
															className={classNames(
																"btn-icon btn-link like btn-neutral",
															)}>
															<i className='tim-icons icon-pencil' />
														</Button>{" "}
														{/* use this button to remove the data row */}
														<Button
															onClick={() => {}}
															color='danger'
															size='sm'
															className={classNames("btn-icon btn-link like")}>
															<i className='tim-icons icon-simple-remove' />
														</Button>{" "}
													</div>
												),
											};
										})}
										filterable
										resizable={false}
										columns={[
											{
												Header: "Qualification Name",
												accessor: "qualificationName",
												sortable: false,
												filterable: false,
											},
											{
												Header: "Difficulty",
												accessor: "difficulty",
												sortable: false,
												filterable: false,
											},

											{
												Header: "Actions",
												accessor: "actions",
												sortable: false,
												filterable: false,
											},
										]}
										defaultPageSize={10}
										showPaginationTop
										showPaginationBottom={false}
										className='-striped -highlight'
									/>
									{/* )} */}
								</CardBody>
							</Card>
						</Col>
						<Col xs={12} md={12}>
							<div className='d-flex mt-5 mb-3'>
								<Button
									className='btn-fill'
									color='default'
									type='submit'
									onClick={() => setOpenModal(true)}>
									Add Personality
								</Button>
							</div>
							{openModal && (
								<div
									style={{
										width: "50%",
										height: "350px",
										backgroundColor: "#D3D3D3",
										borderRadius: "12px",
										padding: "30px 0px 50px 100px",
										marginBottom: "30px",
										zIndex: "100",
									}}>
									<div
										className='d-flex justify-content-end w-100'
										style={{ marginTop: "-10px", paddingRight: "20px" }}>
										<Cross
											onClick={() => setOpenModal(false)}
											style={{ cursor: "pointer" }}
										/>
									</div>
									<Row>
										<Col lg='8' md='6' sm='6'>
											<Label sm='6'>Personality</Label>
											<Select
												className='react-select info'
												classNamePrefix='react-select'
												name='singleSelect'
												value={personality && personality}
												onChange={(value) => handlePersonalityChange(value)}
												options={personalities.map((per) => {
													return {
														value: per.id,
														label: per.name,
													};
												})}
											/>
										</Col>
									</Row>
									<div className='d-flex mt-3 mb-3'>
										<Button
											className='btn-fill'
											color='default'
											type='submit'
											onClick={addPersonality}>
											Save
										</Button>
									</div>
								</div>
							)}
							<Card>
								<CardHeader>Personality</CardHeader>
								<CardBody>
									{roleTemplateForm?.personalities?.length > 0 && (
										<ReactTable
											data={roleTemplateForm.personalities.map((prop, key) => {
												return {
													id: key,
													personalityName: prop.personality_type_name,
													personalityId: prop.personality_type_id,
													actions: (
														<div className='actions-right'>
															{/* use this button to add a edit kind of action */}
															<Button
																onClick={() => {
																	// history.push(
																	// 	`/admin/role-templates/${prop.id}`,
																	// );
																}}
																color='warning'
																size='sm'
																className={classNames(
																	"btn-icon btn-link like btn-neutral",
																)}>
																<i className='tim-icons icon-pencil' />
															</Button>{" "}
															{/* use this button to remove the data row */}
															<Button
																onClick={() => {}}
																color='danger'
																size='sm'
																className={classNames(
																	"btn-icon btn-link like",
																)}>
																<i className='tim-icons icon-simple-remove' />
															</Button>{" "}
														</div>
													),
												};
											})}
											filterable
											resizable={false}
											columns={[
												{
													Header: "Personality Id",
													accessor: "personalityId",
													sortable: false,
													filterable: false,
												},
												{
													Header: "Personality Name",
													accessor: "personalityName",
													sortable: false,
													filterable: false,
												},

												{
													Header: "Actions",
													accessor: "actions",
													sortable: false,
													filterable: false,
												},
											]}
											defaultPageSize={10}
											showPaginationTop
											showPaginationBottom={false}
											className='-striped -highlight'
										/>
									)}
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			)}
		</div>
	);
}
