import * as Actions from "../actions/testPack";
// import * as QuestionActions from "../actions/questions";

const initialState = {
	testPackForm: {
		authorName: "",
		qualification: "",
		type: null,
		category: null,
		questions: [],
	},
	specificTestPack: {},
	newTestPackId: null,
	testPacks: [],
	isSubmitted: false,
	isEditable: false,
	isEditted: false,
	isDeleted: false,
	isQuestionDeleted: false,
	isPublished: false,
};

const testPackReducer = (state = initialState, action) => {
	const data = action.payload;

	switch (action.type) {
		case Actions.SUBMIT_TEST_PACK_END:
			return (state = {
				...state,
				isSubmitted: true,
				newTestPackId: data.id,
			});
		case Actions.HANDLE_CHANGE:
			return (state = {
				...state,
				testPackForm: {
					...state.testPackForm,
					[data.name]: data.value,
				},
			});
		case Actions.HANDLE_SELECT_CHANGE:
			return (state = {
				...state,
				testPackForm: {
					...state.testPackForm,
					type: data,
				},
			});
		case Actions.HANDLE_SELECT_CATEGORY:
			return (state = {
				...state,
				testPackForm: {
					...state.testPackForm,
					category: data,
				},
			});
		case Actions.GET_TEST_PACK:
			return (state = {
				...state,
				testPacks: data,
			});
		case Actions.SET_TEST_PACK_ID:
			return (state = {
				...state,
				newTestPackId: data,
				isEditable: true,
			});
		case Actions.GET_SPECIFIC_TEST_PACK:
			return (state = {
				...state,
				testPackForm: {
					...state.testPackForm,
					authorName: data.author,
					qualification: data.qualification,
					type: {
						value: data.test_pack_type,
						label:
							data.test_pack_type[0].toUpperCase() +
							data.test_pack_type.slice(1),
					},
					category: { category: data.category },
					questions: data.questions,
				},
				isEditable: true,
			});
		case Actions.UPDATE_TEST_PACK:
			return (state = {
				...state,
				isEditted: true,
			});
		case Actions.PUBLISH_TEST_PACK:
			return (state = {
				...state,
				isPublished: true,
			});
		case Actions.DELETE_TEST_PACK:
			return (state = {
				...state,
				testPacks: state.testPacks.filter((testPack) => {
					if (testPack.id !== data) {
						return testPack;
					}
				}),
				isDeleted: true,
			});
		case Actions.DELETE_QUESTION:
			return (state = {
				...state,
				isQuestionDeleted: true,
				testPackForm: {
					...state.testPackForm,
					questions: state.testPackForm.questions.filter((question) => {
						if (question._id !== data) {
							return question;
						}
					}),
				},
			});
		default:
			return state;
	}
};

export default testPackReducer;
