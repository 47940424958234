import axios from "axios";
import baseURL from "../../utilities/BaseUrl";
import {
	addAccessToken,
	handleRequestError,
	handleResponseOK,
	handleResponseError,
} from "./interceptors";

const instance = axios.create({
	baseURL: baseURL,
	withCredentials: true,
	timeout: 60000,
});

const secure_instance = axios.create({
	baseURL: baseURL,
	withCredentials: true,
	timeout: 60000,
});

secure_instance.interceptors.request.use(addAccessToken, handleRequestError);
secure_instance.interceptors.response.use(
	handleResponseOK,
	handleResponseError,
);

export { instance, secure_instance };
