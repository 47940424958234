import React from "react";
import {
	Row,
	Col,
	Label,
	Input,
	FormGroup,
	Button,
	Card,
	CardHeader,
	CardBody,
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import * as QualificationActions from "../redux/actions/qualifications";
import { Redirect } from "react-router-dom";

export default function AddQualifications() {
	const dispatch = useDispatch();
	const isSubmitted = useSelector((state) => state.qualifications.isSubmitted);
	const form = useSelector((state) => state.qualifications.qualificationsForm);
	const id = useSelector((state) => state.roleTemplates.roleTemplateId);
	const testPacks = useSelector((state) => state.testPackReducer.testPacks);

	const submitData = () => {
		dispatch(QualificationActions.submitQualifications({ ...form }, id));
	};

	const handleSelectChange = (value) => {
		dispatch(QualificationActions.handleSelectChange(value));
	};

	const handleChange = (value) => {
		dispatch(QualificationActions.handleChange(value));
	};

	if (isSubmitted) {
		return <Redirect to='/admin/add-role-templates' />;
	}

	return (
		<div className='content'>
			<div
				style={{
					padding: "30px 0px 50px 100px",
				}}>
				<Row>
					<Col lg='5' md='6' sm='3'>
						<Label sm='6'>Qualifications</Label>
						<Select
							className='react-select info'
							classNamePrefix='react-select'
							name='singleSelect'
							value={form?.qualifications}
							onChange={(value) => handleSelectChange(value)}
							options={testPacks.map((testpack, key) => {
								return {
									value: testpack.id,
									label: testpack.qualification,
								};
							})}
						/>
					</Col>
					<Col lg='5' md='6' sm='3'>
						<Label sm='4'>Difficulty</Label>
						<Select
							className='react-select info'
							classNamePrefix='react-select'
							name='singleSelect'
							value={form?.difficulty}
							onChange={(value) => handleChange(value)}
							options={[
								{ value: "1", label: "1" },
								{ value: "2", label: "2" },
								{ value: "3", label: "3" },
								{ value: "4", label: "4" },
								{ value: "5", label: "5" },
							]}
						/>
					</Col>
				</Row>
				<div className='d-flex mt-3 mb-3'>
					<Button
						className='btn-fill'
						color='default'
						type='submit'
						onClick={submitData}>
						Save
					</Button>
				</div>
			</div>
		</div>
	);
}
