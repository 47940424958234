import { secure_instance } from "api/axios/axios-config";
export const SUBMIT_TEST_PACK_START = "[Test Pack] SUBMIT_TEST_PACK_START";
export const SUBMIT_TEST_PACK_END = "[Test Pack] SUBMIT_TEST_PACK_END";
export const HANDLE_CHANGE = "[Test Pack] HANDLE_CHANGE";
export const HANDLE_SELECT_CHANGE = "[Test Pack] HANDLE_SELECT_CHANGE";
export const HANDLE_SELECT_CATEGORY = "[Test Pack] HANDLE_SELECT_CATEGORY";
export const GET_TEST_PACK = "[Test Pack] GET_TEST_PACK";
export const SET_TEST_PACK_ID = "[Test Pack] SET_TEST_PACK_ID";
export const GET_SPECIFIC_TEST_PACK = "[Test Pack] GET_SPECIFIC_TEST_PACK";
export const UPDATE_TEST_PACK = "[Test Pack] UPDATE_TEST_PACK";
export const DELETE_TEST_PACK = "[Test Pack] DELETE_TEST_PACK";
export const DELETE_QUESTION = "[Test Pack] DELETE_QUESTION";
export const PUBLISH_TEST_PACK = "[Test Pack] PUBLISH_TEST_PACK";

export const submitTestPack = (data) => {
	return async (dispatch) => {
		var data_type = data.type.label.toLowerCase();
		var category = data.category && data.category.label;
		try {
			const request = await secure_instance.request({
				url: `v1/test_packs/`,
				method: "Post",
				data: {
					qualification: data.qualification,
					author: data.authorName,
					test_pack_type: data_type,
					category: category,
				},
			});
			// console.log(request, "testpacks")
			dispatch({
				type: SUBMIT_TEST_PACK_END,
				payload: request.data,
			});

			dispatch(getSpecificTestPack(request.data.id));
		} catch (e) {
			console.log(e);
		}
	};
};

export const setTestPackId = (id) => {
	return {
		type: SET_TEST_PACK_ID,
		payload: id,
	};
};

export const getTestPack = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/test_packs/`,
				method: "Get",
			});
			// console.log(request.data, "testpacks");
			dispatch({
				type: GET_TEST_PACK,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};

export const getSpecificTestPack = (id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/test_packs/${id}`,
				method: "Get",
			});
			// console.log(request, "specific");
			dispatch({
				type: GET_SPECIFIC_TEST_PACK,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};

export const publishTestPack = (id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/test_packs/${id}`,
				method: "Patch",
				data: {
					is_published: true,
				},
			});
			console.log(request, "publish");
			dispatch({
				type: PUBLISH_TEST_PACK,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};
export const updateTestPack = (data, id, isPublished) => {
	return async (dispatch) => {
		var data_type = data.type.label.toLowerCase();
		var category = data.category && data.category.label;
		try {
			const request = await secure_instance.request({
				url: `v1/test_packs/${id}`,
				method: "Patch",
				data: {
					qualification: data.qualification,
					author: data.authorName,
					test_pack_type: data_type,
					category: category,
					is_published: isPublished,
				},
			});
			// console.log(request, "update");
			dispatch({
				type: UPDATE_TEST_PACK,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};

export const deleteQuestion = (id, questionId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/test_packs/${id}/question/${questionId}`,
				method: "Delete",
			});

			console.log(request, "delete");

			dispatch({
				type: DELETE_QUESTION,
				payload: questionId,
			});

			dispatch(getSpecificTestPack(id));
		} catch (error) {
			//
		}
	};
};

export const deleteTestPack = (id) => {
	return async (dispatch) => {
		try {
			await secure_instance.request({
				url: `v1/test_packs/${id}`,
				method: "Delete",
			});
			// console.log(request, "delete");
			dispatch({
				type: DELETE_TEST_PACK,
				payload: id,
			});
		} catch (error) {
			//
		}
	};
};

export const handleChange = (data) => {
	return (dispatch) => {
		dispatch({
			type: HANDLE_CHANGE,
			payload: data,
		});
	};
};

export const handleSelectChange = (data) => {
	return {
		type: HANDLE_SELECT_CHANGE,
		payload: data,
	};
};

export const handleSelectCategory = (data) => {
	return {
		type: HANDLE_SELECT_CATEGORY,
		payload: data,
	};
};
