import { secure_instance } from "api/axios/axios-config";
export const SUBMIT_QUESTIONS = "[Questions] SUBMIT_QUESTIONS";
export const HANDLE_CHANGE = "[Questions] HANDLE_CHANGE";
export const HANDLE_SELECT_CHANGE = "[Questions] HANDLE_SELECT_CHANGE";
export const HANDLE_SELECT_QUALIFICATION_CHANGE =
	"[Questions] HANDLE_SELECT_QUALIFICATION_CHANGE";
export const HANDLE_SELECT_QUALIFICATION_CHOICE_CHANGE =
	"[Questions] HANDLE_SELECT_QUALIFICATION_CHOICE_CHANGE";
export const UPLOAD_IMAGE = "[Questions] UPLOAD_IMAGE";
export const UPLOAD_IMAGE1 = "[Questions] UPLOAD_IMAGE1";
export const UPLOAD_IMAGE2 = "[Questions] UPLOAD_IMAGE2";
export const UPLOAD_IMAGE3 = "[Questions] UPLOAD_IMAGE3";
export const UPLOAD_IMAGE4 = "[Questions] UPLOAD_IMAGE4";
export const GET_SPECIFIC_QUESTION = "[Questions] GET_SPECIFIC_QUESTION";
export const SET_QUESTION_ID = "[Questions] SET_QUESTION_ID";
export const UPDATE_QUESTION = "[Questions] UPDATE_QUESTION";
export const GET_SPECIFIC_PERSONALITY_QUESTION =
	"[Questions] GET_SPECIFIC_PERSONALITY_QUESTION";
export const HANDLE_SELECT_DIFFICULTY_CHANGE =
	"[Questions] HANDLE_SELECT_DIFFICULTY_CHANGE";
export const HANDLE_SELECT_ANSWER_CHANGE =
	"[Questions] HANDLE_SELECT_ANSWER_CHANGE";

export const submitQuestions = (data, _id, history) => {
	console.log(data, "data");
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/test_packs/${_id}/question`,
				method: "Post",
				data,
			});
			if (request.status === 200) {
				alert("Question is Added");
			}
			console.log(request, "person");
			dispatch({
				type: SUBMIT_QUESTIONS,
				payload: request.data,
			});

			history.push("/admin/add-testpack");
		} catch (error) {
			//
		}
	};
};

export const getSpecificQuestion = (id, questionId, label) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/test_packs/${id}/question/${questionId}`,
				method: "Get",
			});
			console.log(request, "specific");

			if (label === "Personality") {
				dispatch({
					type: GET_SPECIFIC_PERSONALITY_QUESTION,
					payload: request.data,
				});
			} else {
				dispatch({
					type: GET_SPECIFIC_QUESTION,
					payload: request.data,
				});
			}
		} catch (error) {
			//
		}
	};
};

export const updateQuestion = (data, id, questionId, history) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/test_packs/${id}/question/${questionId}`,
				method: "Patch",
				data,
			});
			if (request.status === 200) {
				alert("Question is updated!");
			}
			console.log(request, "update");

			dispatch({
				type: UPDATE_QUESTION,
				payload: request.data,
			});
			history.push("/admin/add-testpack");
		} catch (error) {
			//
		}
	};
};

// export const deleteQuestion = (id, questionId) => {
// 	return async (dispatch) => {
// 		try {
// 			const request = await secure_instance.request({
// 				url: `v1/test_packs/${id}/question/${questionId}`,
// 				method: "Delete",
// 			});

// 			console.log(request, "delete");

// 			dispatch({
// 				type: DELETE_QUESTION,
// 				payload: questionId,
// 			});
// 		} catch (error) {
// 			//
// 		}
// 	};
// };

export const setQuestionId = (id) => {
	return {
		type: SET_QUESTION_ID,
		payload: id,
	};
};

export const handleChange = (data) => {
	return (dispatch) => {
		dispatch({
			type: HANDLE_CHANGE,
			payload: data,
		});
	};
};

export const uploadImage = (file) => {
	return async (dispatch) => {
		try {
			const formdata = new FormData();
			formdata.append("_file", file);

			const request = await secure_instance.request({
				url: "v1/test_packs/upload_file",
				method: "Post",
				data: formdata,
			});

			console.log(request, "file");

			setTimeout(() => {
				dispatch({
					type: UPLOAD_IMAGE,
					payload: request.data,
				});
			}, 3000);
		} catch (error) {
			//
		}
	};
};

export const uploadImage1 = (file) => {
	return async (dispatch) => {
		try {
			const formdata = new FormData();
			formdata.append("_file", file);

			const request = await secure_instance.request({
				url: "v1/test_packs/upload_file",
				method: "Post",
				data: formdata,
			});

			console.log(request, "file1");

			setTimeout(() => {
				dispatch({
					type: UPLOAD_IMAGE1,
					payload: request.data,
				});
			}, 3000);
		} catch (error) {
			//
		}
	};
};

export const uploadImage2 = (file) => {
	return async (dispatch) => {
		try {
			const formdata = new FormData();
			formdata.append("_file", file);

			const request = await secure_instance.request({
				url: "v1/test_packs/upload_file",
				method: "Post",
				data: formdata,
			});

			console.log(request, "file2");

			setTimeout(() => {
				dispatch({
					type: UPLOAD_IMAGE2,
					payload: request.data,
				});
			}, 3000);
		} catch (error) {
			//
		}
	};
};

export const uploadImage3 = (file) => {
	return async (dispatch) => {
		try {
			const formdata = new FormData();
			formdata.append("_file", file);

			const request = await secure_instance.request({
				url: "v1/test_packs/upload_file",
				method: "Post",
				data: formdata,
			});

			console.log(request, "file3");

			setTimeout(() => {
				dispatch({
					type: UPLOAD_IMAGE3,
					payload: request.data,
				});
			}, 3000);
		} catch (error) {
			//
		}
	};
};

export const uploadImage4 = (file) => {
	return async (dispatch) => {
		try {
			const formdata = new FormData();
			formdata.append("_file", file);

			const request = await secure_instance.request({
				url: "v1/test_packs/upload_file",
				method: "Post",
				data: formdata,
			});

			console.log(request, "file4");

			setTimeout(() => {
				dispatch({
					type: UPLOAD_IMAGE4,
					payload: request.data,
				});
			}, 3000);
		} catch (error) {
			//
		}
	};
};

export const handleSelectChange = (data) => {
	return {
		type: HANDLE_SELECT_CHANGE,
		payload: data,
	};
};

export const handleSelectQualificationChange = (data) => {
	return {
		type: HANDLE_SELECT_QUALIFICATION_CHANGE,
		payload: data,
	};
};

export const handleSelectQualificationChoiceChange = (data) => {
	return {
		type: HANDLE_SELECT_QUALIFICATION_CHOICE_CHANGE,
		payload: data,
	};
};
export const handleSelectDifficultyChange = (data) => {
	return {
		type: HANDLE_SELECT_DIFFICULTY_CHANGE,
		payload: data,
	};
};

export const handleSelectAnswerChange = (data) => {
	return {
		type: HANDLE_SELECT_ANSWER_CHANGE,
		payload: data,
	};
};
