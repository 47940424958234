import React, { useEffect } from "react";
import {
	Row,
	Col,
	Label,
	FormGroup,
	Input,
	CardTitle,
	Button,
} from "reactstrap";
import Select from "react-select";
import ImageUpload from "components/CustomUpload/ImageUpload";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../redux/actions/questions";
import PropTypes from "prop-types";

import defaultImage from "../assets/img/image_placeholder.jpg";
import { useHistory } from "react-router-dom";

export default function Questions({
	avatar,
	addBtnColor,
	addBtnClasses,
	changeBtnColor,
	changeBtnClasses,
	removeBtnColor,
	removeBtnClasses,
}) {
	const dispatch = useDispatch();

	const history = useHistory();

	const form = useSelector((state) => state.testPackReducer.testPackForm);

	console.log(form.type.label);
	const isEditable = useSelector((state) => state.questions.isEditable);

	const handleSelectChange = (value) => {
		dispatch(Actions.handleSelectChange(value));
	};

	const handleSelectQualificationChange = (value) => {
		dispatch(Actions.handleSelectQualificationChange(value));
	};

	const handleSelectDifficultyChange = (val) => {
		dispatch(Actions.handleSelectDifficultyChange(val));
	};

	const handleSelectAnswerChange = (val) => {
		dispatch(Actions.handleSelectAnswerChange(val));
	};

	const handleSelectQualificationChoiceChange = (value) => {
		dispatch(Actions.handleSelectQualificationChoiceChange(value));
	};

	const newTestPackId = useSelector(
		(state) => state.testPackReducer.newTestPackId,
	);

	const handleChange = (target) => {
		dispatch(Actions.handleChange(target));
	};

	// const isImageUploaded = useSelector(
	// 	(state) => state.questions.isImageUploaded,
	// );

	// const isSubmitted = useSelector((state) => state.questions.isSubmitted);
	// const isEditted = useSelector((state) => state.questions.isEditted);
	const questionId = useSelector((state) => state.questions.questionId);
	const questionsForm = useSelector((state) => state.questions.form);
	console.log(questionsForm);
	const [imagePreviewUrl, setImagePreviewUrl] = React.useState(defaultImage);
	const [imagePreviewUrl1, setImagePreviewUrl1] = React.useState(defaultImage);
	const [imagePreviewUrl2, setImagePreviewUrl2] = React.useState(defaultImage);
	const [imagePreviewUrl3, setImagePreviewUrl3] = React.useState(defaultImage);
	const [imagePreviewUrl4, setImagePreviewUrl4] = React.useState(defaultImage);

	const fileInput = React.useRef(null);

	const fileInput1 = React.useRef(null);
	const fileInput2 = React.useRef(null);
	const fileInput3 = React.useRef(null);
	const fileInput4 = React.useRef(null);

	const handleImageChange = (e) => {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		reader.onloadend = () => {
			setImagePreviewUrl(reader.result);
		};
		reader.readAsDataURL(file);
		if (e.target.files[0]) {
			dispatch(Actions.uploadImage(e.target.files[0]));
		}
	};

	const handleImageUpload1 = (e) => {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		reader.onloadend = () => {
			setImagePreviewUrl1(reader.result);
		};
		reader.readAsDataURL(file);
		if (e.target.files[0]) {
			dispatch(Actions.uploadImage1(e.target.files[0]));
		}
	};
	const handleImageUpload2 = (e) => {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		reader.onloadend = () => {
			setImagePreviewUrl2(reader.result);
		};
		reader.readAsDataURL(file);
		if (e.target.files[0]) {
			dispatch(Actions.uploadImage2(e.target.files[0]));
		}
	};
	const handleImageUpload3 = (e) => {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		reader.onloadend = () => {
			setImagePreviewUrl3(reader.result);
		};
		reader.readAsDataURL(file);
		if (e.target.files[0]) {
			dispatch(Actions.uploadImage3(e.target.files[0]));
		}
	};
	const handleImageUpload4 = (e) => {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		reader.onloadend = () => {
			setImagePreviewUrl4(reader.result);
		};
		reader.readAsDataURL(file);
		if (e.target.files[0]) {
			dispatch(Actions.uploadImage4(e.target.files[0]));
		}
	};

	const handleClick = () => {
		fileInput.current.click();
	};

	const handleClick1 = () => {
		fileInput1.current.click();
	};

	const handleClick2 = () => {
		fileInput2.current.click();
	};

	const handleClick3 = () => {
		fileInput3.current.click();
	};

	const handleClick4 = () => {
		fileInput4.current.click();
	};

	const updateForm = () => {
		const data = {
			problem_statement: {
				type: questionsForm.problem_statement_type,
				title: questionsForm.problem_statement,
			},

			duration: {
				minutes: questionsForm.minutes,
				seconds: questionsForm.seconds,
			},
		};

		if (form.type.label === "Qualification") {
			data["problem_statement"]["type"] = questionsForm.problem_type.label;
			if (questionsForm._file) {
				data["problem_statement"]["img_url"] = [questionsForm._file.file_url];
			}

			data["type"] = questionsForm.question_type;
			if (questionsForm.choiceType.label === "text") {
				data["choices"] = [
					{
						type: questionsForm.choiceType.label,
						choice: questionsForm.choice1,
					},
					{
						type: questionsForm.choiceType.label,
						choice: questionsForm.choice2,
					},
					{
						type: questionsForm.choiceType.label,
						choice: questionsForm.choice3,
					},
					{
						type: questionsForm.choiceType.label,
						choice: questionsForm.choice4,
					},
				];
			} else if (questionsForm.choiceType.label === "image") {
				data["choices"] = [
					{
						type: questionsForm.choiceType.label,
						choice: questionsForm.image1.file_url,
					},
					{
						type: questionsForm.choiceType.label,
						choice: questionsForm.image2.file_url,
					},
					{
						type: questionsForm.choiceType.label,
						choice: questionsForm.image3.file_url,
					},
					{
						type: questionsForm.choiceType.label,
						choice: questionsForm.image4.file_url,
					},
				];
			}

			data["answer"] = questionsForm?.answer?.value;
			data["difficulty"] = questionsForm?.difficulty?.value;
		} else if (form.type.label === "Personality") {
			data["type"] = questionsForm.questionType.label;
		}

		dispatch(Actions.updateQuestion(data, newTestPackId, questionId, history));
	};

	const submitForm = () => {
		// console.log(questionsForm._file, "fileuploaded");

		const data = {
			problem_statement: {
				type: questionsForm.problem_statement_type,
				title: questionsForm.problem_statement,
			},

			duration: {
				minutes: questionsForm.minutes,
				seconds: questionsForm.seconds,
			},
		};

		if (form.type.label === "Qualification") {
			data["problem_statement"]["type"] = questionsForm.problem_type.label;
			if (questionsForm.problem_type.label !== "text") {
				data["problem_statement"]["img_url"] = [questionsForm?._file?.file_url];
			}

			data["type"] = questionsForm.question_type;
			if (questionsForm.choiceType.label === "text") {
				data["choices"] = [
					{
						type: questionsForm.choiceType.label,
						choice: questionsForm.choice1,
					},
					{
						type: questionsForm.choiceType.label,
						choice: questionsForm.choice2,
					},
					{
						type: questionsForm.choiceType.label,
						choice: questionsForm.choice3,
					},
					{
						type: questionsForm.choiceType.label,
						choice: questionsForm.choice4,
					},
				];
			} else if (questionsForm.choiceType.label === "image") {
				data["choices"] = [
					{
						type: questionsForm.choiceType.label,
						choice: questionsForm.image1.file_url,
					},
					{
						type: questionsForm.choiceType.label,
						choice: questionsForm.image2.file_url,
					},
					{
						type: questionsForm.choiceType.label,
						choice: questionsForm.image3.file_url,
					},
					{
						type: questionsForm.choiceType.label,
						choice: questionsForm.image4.file_url,
					},
				];
			}

			data["answer"] = questionsForm?.answer?.value;
			data["difficulty"] = questionsForm?.difficulty?.value;
		} else if (form.type.label === "Personality") {
			data["type"] = questionsForm.questionType.label;
		}

		// console.log(data, "data");
		dispatch(Actions.submitQuestions(data, newTestPackId, history));
	};

	useEffect(() => {
		if (isEditable) {
			dispatch(
				Actions.getSpecificQuestion(newTestPackId, questionId, form.type.label),
			);
		}
	}, [dispatch, newTestPackId, questionId, isEditable, form]);

	return (
		<div className='content'>
			<Row>
				<Col lg='5' md='6' sm='3'>
					<Label sm='6'>Question Types</Label>
					{form.type && form.type.label === "Personality" ? (
						<Select
							className='react-select info'
							classNamePrefix='react-select'
							name='singleSelect'
							value={questionsForm.questionType}
							onChange={(value) => handleSelectChange(value)}
							options={[
								{ value: "text", label: "text" },
								{ value: "video", label: "video" },
							]}
							placeholder='Types'
						/>
					) : (
						<FormGroup>
							<Input
								type='text'
								defaultValue={questionsForm.question_type}
								name='type'
								readOnly
							/>
						</FormGroup>
					)}
				</Col>
				<Col lg='5' md='6' sm='3'>
					<Label sm='12'> Problem Statement Title</Label>
					<FormGroup>
						<Input
							type='text'
							name='problem_statement'
							value={questionsForm.problem_statement}
							onChange={(e) => handleChange(e.target)}
						/>
					</FormGroup>
				</Col>
			</Row>

			{form.type && form.type.label === "Personality" ? (
				<>
					{" "}
					<Row>
						<Col lg='5' md='6' sm='3'>
							<Label sm='6'>Duration(minutes)</Label>
							<FormGroup>
								<Input
									type='number'
									name='minutes'
									value={questionsForm.minutes}
									onChange={(e) => handleChange(e.target)}
								/>
							</FormGroup>
						</Col>
						<Col lg='5' md='6' sm='3'>
							<Label sm='6'>Duration(seconds)</Label>
							<FormGroup>
								<Input
									type='number'
									name='seconds'
									value={questionsForm.seconds}
									onChange={(e) => handleChange(e.target)}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col lg='5' md='6' sm='3'>
							<Label sm='6'>Problem Statement Type</Label>
							<FormGroup>
								<Input
									type='text'
									name='type'
									defaultValue={questionsForm.problem_statement_type}
									readOnly
								/>
							</FormGroup>
						</Col>
					</Row>
				</>
			) : (
				<>
					<Row>
						<Col lg='5' md='6' sm='3'>
							<Label sm='6'>Problem Statement Types</Label>
							<Select
								className='react-select info'
								classNamePrefix='react-select'
								name='singleSelect'
								value={questionsForm.problem_type}
								onChange={(value) => handleSelectQualificationChange(value)}
								options={[
									{ value: "text", label: "text" },
									{ value: "image", label: "image" },
									{ value: "video", label: "video" },
								]}
								placeholder='Types'
							/>
						</Col>
						<Col lg='5' md='6' sm='3'>
							<Label sm='6'>Difficulty</Label>
							<FormGroup>
								<Select
									className='react-select info'
									classNamePrefix='react-select'
									name='singleSelect'
									value={questionsForm.difficulty}
									onChange={(value) => handleSelectDifficultyChange(value)}
									options={[
										{ value: "1", label: "1" },
										{ value: "2", label: "2" },
										{ value: "3", label: "3" },
										{ value: "4", label: "4" },
										{ value: "5", label: "5" },
									]}
									placeholder='Types'
								/>
								{/* <Input
									type='number'
									name='difficulty'
									value={questionsForm.difficulty}
									onChange={(e) => handleChange(e.target)}
								/> */}
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col lg='5' md='6' sm='3'>
							<Label sm='6'>Duration(minutes)</Label>
							<FormGroup>
								<Input
									type='number'
									name='minutes'
									value={questionsForm.minutes}
									onChange={(e) => handleChange(e.target)}
								/>
							</FormGroup>
						</Col>
						<Col lg='5' md='6' sm='3'>
							<Label sm='6'>Duration(seconds)</Label>
							<FormGroup>
								<Input
									type='number'
									name='seconds'
									value={questionsForm.seconds}
									onChange={(e) => handleChange(e.target)}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						{form.type && form.type.label === "Qualification" && (
							<Col lg='5' md='6' sm='3'>
								<Label sm='6'>Choices Type</Label>
								<Select
									className='react-select info'
									classNamePrefix='react-select'
									name='singleSelect'
									value={questionsForm.choiceType}
									onChange={(value) =>
										handleSelectQualificationChoiceChange(value)
									}
									options={[
										{ value: "text", label: "text" },
										{ value: "image", label: "image" },
										{ value: "video", label: "video" },
									]}
									placeholder='Types'
								/>
							</Col>
						)}
						<Col lg='5' md='6' sm='3'>
							<Label sm='6'>Answer</Label>
							<FormGroup>
								<Select
									className='react-select info'
									classNamePrefix='react-select'
									name='singleSelect'
									value={questionsForm.answer}
									onChange={(value) => handleSelectAnswerChange(value)}
									options={[
										{ value: "1", label: "1" },
										{ value: "2", label: "2" },
										{ value: "3", label: "3" },
										{ value: "4", label: "4" },
									]}
									placeholder='Types'
								/>
							</FormGroup>
						</Col>
					</Row>

					<Row>
						{questionsForm.problem_type &&
							questionsForm.problem_type.label === "image" && (
								<Col lg='5' md='6' sm='3' style={{ marginTop: "20px" }}>
									<CardTitle tag='h5'>Problem Statement Image</CardTitle>
									<div className='fileinput text-center'>
										<input
											type='file'
											onChange={handleImageChange}
											ref={fileInput}
										/>
										<div className='thumbnail'>
											<img src={imagePreviewUrl} alt='...' />
										</div>
										<div>
											{questionsForm._file === null ? (
												<Button
													color={addBtnColor}
													className={addBtnClasses}
													onClick={() => handleClick()}>
													{avatar ? "Add Photo" : "Select image"}
												</Button>
											) : (
												<span>
													<Button
														color={changeBtnColor}
														className={changeBtnClasses}
														onClick={() => handleClick()}>
														Change
													</Button>
												</span>
											)}
										</div>
									</div>
								</Col>
							)}
					</Row>

					{questionsForm.choiceType &&
						questionsForm.choiceType.label === "image" && (
							<div style={{ marginTop: "30px" }}>
								<Row>
									<Col md='3' sm='4'>
										<CardTitle tag='h5'>Upload Choice 1</CardTitle>
										<div className='fileinput text-center'>
											<input
												type='file'
												onChange={handleImageUpload1}
												ref={fileInput1}
											/>
											<div className='thumbnail'>
												<img src={imagePreviewUrl1} alt='...' />
											</div>
											<div>
												{questionsForm.image1 === null ? (
													<Button
														color={addBtnColor}
														className={addBtnClasses}
														onClick={() => handleClick1()}>
														{avatar ? "Add Photo" : "Select image"}
													</Button>
												) : (
													<span>
														<Button
															color={changeBtnColor}
															className={changeBtnClasses}
															onClick={() => handleClick1()}>
															Change
														</Button>
													</span>
												)}
											</div>
										</div>
									</Col>

									<Col md='3' sm='4'>
										<CardTitle tag='h5'>Upload Choice 2</CardTitle>
										<div className='fileinput text-center'>
											<input
												type='file'
												onChange={handleImageUpload2}
												ref={fileInput2}
											/>
											<div className='thumbnail'>
												<img src={imagePreviewUrl2} alt='...' />
											</div>
											<div>
												{questionsForm.image2 === null ? (
													<Button
														color={addBtnColor}
														className={addBtnClasses}
														onClick={() => handleClick2()}>
														{avatar ? "Add Photo" : "Select image"}
													</Button>
												) : (
													<span>
														<Button
															color={changeBtnColor}
															className={changeBtnClasses}
															onClick={() => handleClick2()}>
															Change
														</Button>
													</span>
												)}
											</div>
										</div>
									</Col>

									<Col md='3' sm='4'>
										<CardTitle tag='h5'>Upload Choice 3</CardTitle>
										<div className='fileinput text-center'>
											<input
												type='file'
												onChange={handleImageUpload3}
												ref={fileInput3}
											/>
											<div className='thumbnail'>
												<img src={imagePreviewUrl3} alt='...' />
											</div>
											<div>
												{questionsForm.image3 === null ? (
													<Button
														color={addBtnColor}
														className={addBtnClasses}
														onClick={() => handleClick3()}>
														{avatar ? "Add Photo" : "Select image"}
													</Button>
												) : (
													<span>
														<Button
															color={changeBtnColor}
															className={changeBtnClasses}
															onClick={() => handleClick3()}>
															Change
														</Button>
													</span>
												)}
											</div>
										</div>
									</Col>

									<Col md='3' sm='4'>
										<CardTitle tag='h5'>Upload Choice 4</CardTitle>
										<div className='fileinput text-center'>
											<input
												type='file'
												onChange={handleImageUpload4}
												ref={fileInput4}
											/>
											<div className='thumbnail'>
												<img src={imagePreviewUrl4} alt='...' />
											</div>
											<div>
												{questionsForm.image4 === null ? (
													<Button
														color={addBtnColor}
														className={addBtnClasses}
														onClick={() => handleClick4()}>
														{avatar ? "Add Photo" : "Select image"}
													</Button>
												) : (
													<span>
														<Button
															color={changeBtnColor}
															className={changeBtnClasses}
															onClick={() => handleClick4()}>
															Change
														</Button>
													</span>
												)}
											</div>
										</div>
									</Col>
								</Row>
							</div>
						)}
					{questionsForm.choiceType &&
						questionsForm.choiceType.label === "text" && (
							<Row style={{ marginTop: "30px" }}>
								<Col lg='5' md='6' sm='3'>
									<Label sm='6'>Enter Choice 1</Label>
									<FormGroup>
										<Input
											type='text'
											name='choice1'
											value={questionsForm.choice1}
											onChange={(e) => handleChange(e.target)}
										/>
									</FormGroup>
								</Col>
								<Col lg='5' md='6' sm='3'>
									<Label sm='6'>Enter Choice 2</Label>
									<FormGroup>
										<Input
											type='text'
											name='choice2'
											value={questionsForm.choice2}
											onChange={(e) => handleChange(e.target)}
										/>
									</FormGroup>
								</Col>
								<Col lg='5' md='6' sm='3'>
									<Label sm='6'>Enter Choice 3</Label>
									<FormGroup>
										<Input
											type='text'
											name='choice3'
											value={questionsForm.choice3}
											onChange={(e) => handleChange(e.target)}
										/>
									</FormGroup>
								</Col>
								<Col lg='5' md='6' sm='3'>
									<Label sm='6'>Enter Choice 4</Label>
									<FormGroup>
										<Input
											type='text'
											name='choice4'
											value={questionsForm.choice4}
											onChange={(e) => handleChange(e.target)}
										/>
									</FormGroup>
								</Col>
							</Row>
						)}
				</>
			)}
			{isEditable ? (
				<div className='d-flex mt-3'>
					<Button
						className='btn-fill'
						color='default'
						type='submit'
						onClick={updateForm}>
						Update
					</Button>
				</div>
			) : (
				<div className='d-flex mt-3'>
					<Button
						className='btn-fill'
						color='default'
						type='submit'
						onClick={submitForm}>
						Save
					</Button>
				</div>
			)}
		</div>
	);
}

ImageUpload.defaultProps = {
	avatar: false,
	removeBtnClasses: "btn-round",
	removeBtnColor: "danger",
	addBtnClasses: "btn-round",
	addBtnColor: "primary",
	changeBtnClasses: "btn-round",
	changeBtnColor: "primary",
};

ImageUpload.propTypes = {
	avatar: PropTypes.bool,
	removeBtnClasses: PropTypes.string,
	removeBtnColor: PropTypes.oneOf([
		"default",
		"primary",
		"secondary",
		"success",
		"info",
		"warning",
		"danger",
		"link",
	]),
	addBtnClasses: PropTypes.string,
	addBtnColor: PropTypes.oneOf([
		"default",
		"primary",
		"secondary",
		"success",
		"info",
		"warning",
		"danger",
		"link",
	]),
	changeBtnClasses: PropTypes.string,
	changeBtnColor: PropTypes.oneOf([
		"default",
		"primary",
		"secondary",
		"success",
		"info",
		"warning",
		"danger",
		"link",
	]),
};
