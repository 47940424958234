import * as Actions from "../actions/qualifications";

const initialState = {
	qualificationsForm: {
		qualifications: null,
		difficulty: null,
	},
	isSubmitted: false,
	isDeleted: false,
};

const qualificationReducer = (state = initialState, action) => {
	const data = action.payload;

	switch (action.type) {
		case Actions.SUBMIT_QUALIFICATIONS:
			return (state = {
				...state,
				isSubmitted: true,
			});
		case Actions.HANDLE_SELECT_CHANGE:
			return (state = {
				...state,
				qualificationsForm: {
					...state.qualificationsForm,
					qualifications: data,
				},
			});
		case Actions.HANDLE_CHANGE:
			return (state = {
				...state,
				qualificationsForm: {
					...state.qualificationsForm,
					difficulty: data,
				},
			});
		case Actions.DELETE_QUALIFICATIONS:
			return (state = {
				...state,
				isDeleted: true,
			});
		default:
			return state;
	}
};

export default qualificationReducer;
