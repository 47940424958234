/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
// import classnames from "classnames";
// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Col,
} from "reactstrap";

import { Redirect } from "react-router-dom";
import * as authActions from "../../redux/actions/authAction";
import { useSelector, useDispatch } from "react-redux";
import { isEmailValid, isPasswordValid } from "../../utilities/Validator";
// import BaseURL from "../../utilities/BaseUrl";
// import axios from "axios";

const Login = () => {
	const dispatch = useDispatch();

	const errorMessage = useSelector((state) => state.user.errorMessage);
	const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

	const [user, setUser] = useState({
		email: null,
		password: null,
	});

	const [errors, setErrors] = useState({
		email: false,
		password: false,
		invalid: false,
	});

	function handleInput(event) {
		setUser({ ...user, [event.target.name]: event.target.value });
	}

	function isEmailOkay() {
		if (user.email === null) {
			return false;
		}

		if (errors.email) {
			return false;
		}

		return true;
	}

	// function isPasswordOkay() {
	// 	if (user.password === null) {
	// 		return false;
	// 	}

	// 	if (errors.password) {
	// 		return false;
	// 	}

	// 	return true;
	// }

	function validateForm() {
		if (user.email === null) {
			return { isValid: false, message: "Email is missing" };
		}

		if (errors.email) {
			return { isValid: false, message: "Email is Invalid" };
		}

		if (user.password === null) {
			return { isValid: false, message: "Password is missing" };
		}

		return { isValid: true, message: "" };
	}

	function handleSubmit(event) {
		event.preventDefault();

		const validation = validateForm();

		if (!validation.isValid) {
			return dispatch(authActions.setErrorMessage(validation.message));
		}

		return dispatch(authActions.signIn(user.email, user.password));
	}

	function onBlur(event) {
		if (event.target.name === "email") {
			if (!isEmailValid(user[event.target.name])) {
				setErrors({ ...errors, [event.target.name]: true });
				return;
			}
		}

		if (event.target.name === "password") {
			if (!isPasswordValid(user[event.target.name])) {
				setErrors({ ...errors, [event.target.name]: true });
				return;
			}
		}

		setErrors({ ...errors, [event.target.name]: false });
	}

	useEffect(() => {
		onBlur({ target: { name: "email" } });
	}, []);

	if (isLoggedIn) {
		return <Redirect to='/admin/testpacks' />;
	}

	// const [state, setState] = React.useState({});
	// React.useEffect(() => {
	// 	document.body.classList.toggle("login-page");
	// 	return function cleanup() {
	// 		document.body.classList.toggle("login-page");
	// 	};
	// });

	// useEffect(() => {
	// 	axios
	// 		.post(`${BaseURL}/v1/auth/login`, {
	// 			email: "jaffar@jaffar.com",
	// 			password: "12345678",
	// 		})
	// 		.then((res) => console.log(res, "success"))
	// 		.catch((err) => console.log(err, "error"));
	// }, []);
	return (
		<>
			<div className='content'>
				<Container>
					<Col className='ml-auto mr-auto' lg='4' md='6'>
						<Form className='form'>
							<Card className='card-login card-white'>
								<CardHeader>
									<img
										alt='...'
										src={require("assets/img/card-primary.png").default}
									/>
									<CardTitle tag='h1'>Log in</CardTitle>
								</CardHeader>
								<CardBody>
									<InputGroup>
										<InputGroupAddon addonType='prepend'>
											<InputGroupText>
												<i className='tim-icons icon-email-85' />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											name='email'
											placeholder='Email'
											type='text'
											onBlur={onBlur}
											onChange={handleInput}
											className={
												"has-label" + isEmailOkay() ? "" : "has-danger"
											}
										/>
									</InputGroup>
									<InputGroup>
										<InputGroupAddon addonType='prepend'>
											<InputGroupText>
												<i className='tim-icons icon-lock-circle' />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											name='password'
											placeholder='Password'
											type='text'
											onChange={handleInput}
											onBlur={onBlur}
										/>
									</InputGroup>
									{errorMessage && (
										<label className='error'>{errorMessage}</label>
									)}
								</CardBody>

								<CardFooter>
									<Button
										block
										className='mb-3'
										color='primary'
										href='#pablo'
										onClick={(e) => handleSubmit(e)}
										size='lg'>
										Get Started
									</Button>
									{/* <div className="pull-left">
                    <h6>
                      <a
                        className="link footer-link"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        Create Account
                      </a>
                    </h6>
                  </div> */}
									<div className='pull-right'>
										<h6>
											<a
												className='link footer-link'
												href='#pablo'
												onClick={(e) => e.preventDefault()}>
												Need Help?
											</a>
										</h6>
									</div>
								</CardFooter>
							</Card>
						</Form>
					</Col>
				</Container>
			</div>
		</>
	);
};

export default Login;
