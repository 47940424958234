import * as Actions from "../actions/roleTemplates";

const initialState = {
	form: {
		name: "",
		description: "",
		category:"",
		qualifications: [],
		personalities: [],
	},
	personality: null,
	personalities: [],
	categories:[],
	roleTemplateId: null,
	isSubmitted: false,
	roleTemplates: [],
	isEditable: false,
	isEditted: false,
	personalitySubmitted: false,
	categorySubmitted: false,
	isDeleted: false,
	isPersonalityDeleted: false,
};

const roleTemplatesReducer = (state = initialState, action) => {
	const data = action.payload;

	switch (action.type) {
		case Actions.GET_ALL_ROLE_TEMPLATES:
			return (state = {
				...state,
				roleTemplates: data,
			});
		case Actions.GET_ALL_CATEGORY_TYPES:
			return (state = {
				...state,
				categories: data,
			});
		case Actions.ADD_ROLE_TEMPLATE:
			return (state = {
				...state,
				isSubmitted: true,
				roleTemplateId: data._id,
			});
		case Actions.HANDLE_CHANGE:
			return (state = {
				...state,
				form: {
					...state.form,
					[data.name]: data.value,
				},
			});
		case Actions.SET_ROLE_TEMPLATE_ID:
			return (state = {
				...state,
				roleTemplateId: data,
				isEditable: true,
			});
		case Actions.GET_SPECIFIC_ROLE_TEMPLATE:
			return (state = {
				...state,
				form: {
					...state.form,
					name: data.name,
					description: data.description,
					qualifications: data.qualification_mappings,
					personalities: data.personality_mappings,
				},
			});
		case Actions.UPDATE_ROLE_TEMPLATE:
			return (state = {
				...state,
				isEditted: true,
			});
		case Actions.HANDLE_PERSONALITY_CHANGE:
			return (state = {
				...state,
				personality: data,
			});
		case Actions.HANDLE_CATEGORY_CHANGE:
			return (state = {
				...state,
				category: data,
			});
		case Actions.GET_ALL_PERSONALITY:
			return (state = {
				...state,
				personalities: data,
			});
		case Actions.ADD_PERSONALITY:
			return (state = {
				...state,
				personalitySubmitted: true,
			});
		case Actions.ADD_CATEGORY:
			return (state = {
				...state,
				categorySubmitted: true,
			});
		case Actions.DELETE_ROLE_TEMPLATE:
			return (state = {
				...state,
				isDeleted: true,
			});
		case Actions.DELETE_PERSONALITY:
			return (state = {
				...state,
				isPersonalityDeleted: true,
			});
		default:
			return state;
	}
};

export default roleTemplatesReducer;
