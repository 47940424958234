import { combineReducers } from "redux";

//Add Additional Reducers Here
import testPackReducer from "./testPack";
import UserReducer from "./User";
import qualificationReducer from "./qualifications";
import questionsReducer from "./questions";
import roleTemplatesReducer from "./roleTemplates";

//add here also and give the name to the reducer
const allReducers = combineReducers({
	user: UserReducer,
	testPackReducer,
	qualifications: qualificationReducer,
	questions: questionsReducer,
	roleTemplates: roleTemplatesReducer,
});

export default allReducers;
