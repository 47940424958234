import * as Actions from "../actions/questions";

const initialState = {
	form: {
		questionType: null,
		problem_statement_type: "text",
		problem_statement: "",
		minutes: 0,
		seconds: 0,
		question_type: "mcq",
		problem_type: null,
		difficulty: null,
		answer: null,
		choiceType: null,
		choice1: "",
		choice2: "",
		choice3: "",
		choice4: "",
		_file: null,
		image1: null,
		image2: null,
		image3: null,
		image4: null,
	},
	questionId: null,
	isEditable: false,
	isImageUploaded: false,
	isSubmitted: false,
	isEditted: false,
	// isDeleted: true,
};

const questionsReducer = (state = initialState, action) => {
	const data = action.payload;

	switch (action.type) {
		case Actions.SUBMIT_QUESTIONS:
			return (state = {
				...state,
				form: {
					...initialState.form,
				},
				questionId: data._id,
			});
		case Actions.HANDLE_CHANGE:
			return (state = {
				...state,
				form: {
					...state.form,
					[data.name]: data.value,
				},
			});
		case Actions.HANDLE_SELECT_CHANGE:
			return (state = {
				...state,
				form: {
					...state.form,
					questionType: data,
				},
			});
		case Actions.HANDLE_SELECT_QUALIFICATION_CHANGE:
			return (state = {
				...state,
				form: {
					...state.form,
					problem_type: data,
				},
			});
		case Actions.HANDLE_SELECT_QUALIFICATION_CHOICE_CHANGE:
			return (state = {
				...state,
				form: {
					...state.form,
					choiceType: data,
				},
			});
		case Actions.HANDLE_SELECT_DIFFICULTY_CHANGE:
			return (state = {
				...state,
				form: {
					...state.form,
					difficulty: data,
				},
			});
		case Actions.HANDLE_SELECT_ANSWER_CHANGE:
			return (state = {
				...state,
				form: {
					...state.form,
					answer: data,
				},
			});
		case Actions.UPLOAD_IMAGE:
			return (state = {
				...state,
				form: {
					...state.form,
					_file: data,
					isImageUploaded: true,
				},
			});
		case Actions.UPLOAD_IMAGE1:
			return (state = {
				...state,
				form: {
					...state.form,
					image1: data,
				},
			});
		case Actions.UPLOAD_IMAGE2:
			return (state = {
				...state,
				form: {
					...state.form,
					image2: data,
				},
			});
		case Actions.UPLOAD_IMAGE3:
			return (state = {
				...state,
				form: {
					...state.form,
					image3: data,
				},
			});
		case Actions.UPLOAD_IMAGE4:
			return (state = {
				...state,
				form: {
					...state.form,
					image4: data,
				},
			});
		case Actions.GET_SPECIFIC_PERSONALITY_QUESTION:
			return (state = {
				...state,
				form: {
					...state.form,
					problem_statement: data.problem_statement.title,
					questionType: {
						value: data.problem_statement.type,
						label: data.problem_statement.type,
					},
					minutes: data.duration.minutes,
					seconds: data.duration.seconds,
				},
			});
		case Actions.GET_SPECIFIC_QUESTION:
			return (state = {
				...state,

				form: {
					...state.form,
					problem_statement: data.problem_statement.title,
					questionType: {
						value: data.problem_statement.type,
						label: data.problem_statement.type,
					},
					minutes: data.duration.minutes,
					seconds: data.duration.seconds,
					problem_type: {
						value: data.problem_statement.type,
						label: data.problem_statement.type,
					},
					difficulty: {
						value: data.difficulty,
						label: data.difficulty,
					},
					answer: {
						value: data.answer,
						label: data.answer,
					},
					choiceType: {
						value: data.choices[0].type,
						label: data.choices[0].type,
					},
					choice1:
						data.choices[0].type === "text" ? data.choices[0].choice : "",
					choice2:
						data.choices[1].type === "text" ? data.choices[1].choice : "",
					choice3:
						data.choices[2].type === "text" ? data.choices[2].choice : "",
					choice4:
						data.choices[3].type === "text" ? data.choices[3].choice : "",
					_file:
						data.problem_statement.type === "image"
							? data.problem_statement.img_url[0]
							: null,
					image1:
						data.choices[0].type === "image" ? data.choices[0].choice : null,
					image2:
						data.choices[1].type === "image" ? data.choices[1].choice : null,
					image3:
						data.choices[2].type === "image" ? data.choices[2].choice : null,
					image4:
						data.choices[3].type === "image" ? data.choices[3].choice : null,
				},
				isEditable: true,
			});
		case Actions.SET_QUESTION_ID:
			return (state = {
				...state,
				questionId: data,
				isEditable: true,
			});
		case Actions.UPDATE_QUESTION:
			return (state = {
				...state,
				isEditted: true,
			});

		default:
			return state;
	}
};

export default questionsReducer;
