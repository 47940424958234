/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import RoleTemplates from "./views/RoleTemplates.js";
import TestPack from "views/TestPack.js";
import ReactTables from "views/tables/ReactTables.js";
import Wizard from "views/forms/Wizard.js";
import ValidationForms from "views/forms/ValidationForms.js";
import ExtendedForms from "views/forms/ExtendedForms.js";
import RegularForms from "views/forms/RegularForms.js";
// import Register from "views/pages/Register.js";
import Login from "views/pages/Login.js";
import Dashboard from "views/Dashboard.js";
import Questions from "views/Questions.js";
import AddQualifications from "views/AddQualifications.js";
// import AddPersonality from "views/AddPersonality.js";

// import VectorMap from "views/maps/VectorMap.js";
// import GoogleMaps from "views/maps/GoogleMaps.js";
// import FullScreenMap from "views/maps/FullScreenMap.js";
// import RegularTables from "views/tables/RegularTables.js";
// import ExtendedTables from "views/tables/ExtendedTables.js";
// import Calendar from "views/Calendar.js";
// import Widgets from "views/Widgets.js";
// import Charts from "views/Charts.js";
// import Buttons from "views/components/Buttons.js";
// import SweetAlert from "views/components/SweetAlert.js";
// import Notifications from "views/components/Notifications.js";
// import Grid from "views/components/Grid.js";
// import Typography from "views/components/Typography.js";
// import Panels from "views/components/Panels.js";
// import Icons from "views/components/Icons.js";
// import Pricing from "views/pages/Pricing.js";
// import Timeline from "views/pages/Timeline.js";
// import User from "views/pages/User.js";
// import Rtl from "views/pages/Rtl.js";
// import Lock from "views/pages/Lock.js";

const routes = [
	{
		path: "/testpacks",
		name: "Test Packs",
		rtlName: "لوحة القيادة",
		icon: "tim-icons icon-chart-pie-36",
		component: Dashboard,
		layout: "/admin",
	},
	// {
	// 	path: "/register",
	// 	name: "Register",
	// 	rtlName: "تسجيل",
	// 	component: Register,
	// 	icon: "tim-icons icon-image-02",
	// 	layout: "/auth",
	// },
	{
		path: "/login",
		name: "Login",
		rtlName: "هعذاتسجيل الدخول",
		icon: "tim-icons icon-image-02",
		component: Login,
		layout: "/auth",
	},
	{
		path: "/add-role-templates",
		name: "Role Templates",
		rtlName: "هعذاتسجيل الدخول",
		icon: "tim-icons icon-image-02",
		component: RoleTemplates,
		layout: "/admin",
	},
	{
		path: "/add-testpack",
		name: "Add TestPack",
		component: TestPack,
		layout: "/admin",
	},
	{
		path: "/questions",
		name: "Add Questions",
		rtlName: "هعذاتسجيل الدخول",
		component: Questions,
		layout: "/admin",
	},
	// {
	// 	collapse: true,
	// 	name: "Pages",
	// 	rtlName: "صفحات",
	// 	icon: "tim-icons icon-image-02",
	// 	state: "pagesCollapse",
	// 	views: [
	// 		{
	// 			path: "/pricing",
	// 			name: "Pricing",
	// 			rtlName: "عالتسعير",
	// 			mini: "P",
	// 			rtlMini: "ع",
	// 			component: Pricing,
	// 			layout: "/auth",
	// 		},
	// 		{
	// 			path: "/rtl-support",
	// 			name: "RTL Support",
	// 			rtlName: "صودعم رتل",
	// 			mini: "RS",
	// 			rtlMini: "صو",
	// 			component: Rtl,
	// 			layout: "/rtl",
	// 		},
	// 		{
	// 			path: "/timeline",
	// 			name: "Timeline",
	// 			rtlName: "تيالجدول الزمني",
	// 			mini: "T",
	// 			rtlMini: "تي",
	// 			component: Timeline,
	// 			layout: "/admin",
	// 		},
	// 		{
	// 			path: "/login",
	// 			name: "Login",
	// 			rtlName: "هعذاتسجيل الدخول",
	// 			mini: "L",
	// 			rtlMini: "هعذا",
	// 			component: Login,
	// 			layout: "/auth",
	// 		},
	// 		{
	// 			path: "/register",
	// 			name: "Register",
	// 			rtlName: "تسجيل",
	// 			mini: "R",
	// 			rtlMini: "صع",
	// 			component: Register,
	// 			layout: "/auth",
	// 		},
	// 		{
	// 			path: "/lock-screen",
	// 			name: "Lock Screen",
	// 			rtlName: "اقفل الشاشة",
	// 			mini: "LS",
	// 			rtlMini: "هذاع",
	// 			component: Lock,
	// 			layout: "/auth",
	// 		},
	// 		{
	// 			path: "/user-profile",
	// 			name: "User Profile",
	// 			rtlName: "ملف تعريفي للمستخدم",
	// 			mini: "UP",
	// 			rtlMini: "شع",
	// 			component: User,
	// 			layout: "/admin",
	// 		},
	// 	],
	// },

	// {
	// 	collapse: true,
	// 	name: "Forms",
	// 	rtlName: "إستمارات",
	// 	icon: "tim-icons icon-notes",
	// 	state: "formsCollapse",
	// 	views: [
	// 		{
	// 			path: "/regular-forms",
	// 			name: "Regular Forms",
	// 			rtlName: "أشكال عادية",
	// 			mini: "RF",
	// 			rtlMini: "صو",
	// 			component: RegularForms,
	// 			layout: "/admin",
	// 		},
	// 		{
	// 			path: "/extended-forms",
	// 			name: "Extended Forms",
	// 			rtlName: "نماذج موسعة",
	// 			mini: "EF",
	// 			rtlMini: "هوو",
	// 			component: ExtendedForms,
	// 			layout: "/admin",
	// 		},
	// 		{
	// 			path: "/validation-forms",
	// 			name: "Validation Forms",
	// 			rtlName: "نماذج التحقق من الصحة",
	// 			mini: "VF",
	// 			rtlMini: "تو",
	// 			component: ValidationForms,
	// 			layout: "/admin",
	// 		},
	// 		{
	// 			path: "/wizard",
	// 			name: "Wizard",
	// 			rtlName: "ساحر",
	// 			mini: "W",
	// 			rtlMini: "ث",
	// 			component: Wizard,
	// 			layout: "/admin",
	// 		},
	// 	],
	// },
	{
		path: "/role-templates",
		name: "Role Templates",
		rtlName: "رد فعل الطاولة",

		icon: "tim-icons icon-puzzle-10",
		component: ReactTables,
		layout: "/admin",
	},
	{
		path: "/add-qualifications",
		name: "Add Qualifications",
		rtlName: "رد فعل الطاولة",

		icon: "tim-icons icon-puzzle-10",
		component: AddQualifications,
		layout: "/admin",
	},
	// {
	// 	path: "/add-personality",
	// 	name: "Add Personality",
	// 	rtlName: "رد فعل الطاولة",

	// 	icon: "tim-icons icon-puzzle-10",
	// 	component: AddPersonality,
	// 	layout: "/admin",
	// },
];

export default routes;
